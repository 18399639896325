/* Grid styles */

.column {
  margin: 10px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}

.widescreen .column {
  margin: 10px 15px;
}

.column.no-margin {
  margin: 0;
}

.column.pad-instead {
  margin: 0;
}

.widescreen .column.pad-instead {
  padding: 15px;
}

.span-1 {
  width: calc(8.33333% - 20px);
}

.widescreen .span-1 {
  width: calc(8.33333% - 30px);
}

.pad-1 {
  padding-left: 8.33333%;
  box-sizing: content-box;
}

.offset-1 {
  margin-left: calc(8.33333% + 10px);
}

.widescreen .offset-1 {
  margin-left: calc(8.33333% + 15px);
}

.span-2 {
  width: calc(16.66667% - 20px);
}

.widescreen .span-2 {
  width: calc(16.66667% - 30px);
}

.pad-2 {
  padding-left: 16.66667%;
  box-sizing: content-box;
}

.offset-2 {
  margin-left: calc(16.66667% + 10px);
}

.widescreen .offset-2 {
  margin-left: calc(16.66667% + 15px);
}

.span-3 {
  width: calc(25% - 20px);
}

.widescreen .span-3 {
  width: calc(25% - 30px);
}

.pad-3 {
  padding-left: 25%;
  box-sizing: content-box;
}

.offset-3 {
  margin-left: calc(25% + 10px);
}

.widescreen .offset-3 {
  margin-left: calc(25% + 15px);
}

.span-4 {
  width: calc(33.33333% - 20px);
}

.widescreen .span-4 {
  width: calc(33.33333% - 30px);
}

.pad-4 {
  padding-left: 33.33333%;
  box-sizing: content-box;
}

.offset-4 {
  margin-left: calc(33.33333% + 10px);
}

.widescreen .offset-4 {
  margin-left: calc(33.33333% + 15px);
}

.span-5 {
  width: calc(41.66667% - 20px);
}

.widescreen .span-5 {
  width: calc(41.66667% - 30px);
}

.pad-5 {
  padding-left: 41.66667%;
  box-sizing: content-box;
}

.offset-5 {
  margin-left: calc(41.66667% + 10px);
}

.widescreen .offset-5 {
  margin-left: calc(41.66667% + 15px);
}

.span-6 {
  width: calc(50% - 20px);
}

.widescreen .span-6 {
  width: calc(50% - 30px);
}

.pad-6 {
  padding-left: 50%;
  box-sizing: content-box;
}

.offset-6 {
  margin-left: calc(50% + 10px);
}

.widescreen .offset-6 {
  margin-left: calc(50% + 15px);
}

.span-7 {
  width: calc(58.33333% - 20px);
}

.widescreen .span-7 {
  width: calc(58.33333% - 30px);
}

.pad-7 {
  padding-left: 58.33333%;
  box-sizing: content-box;
}

.offset-7 {
  margin-left: calc(58.33333% + 10px);
}

.widescreen .offset-7 {
  margin-left: calc(58.33333% + 15px);
}

.span-8 {
  width: calc(66.66667% - 20px);
}

.widescreen .span-8 {
  width: calc(66.66667% - 30px);
}

.pad-8 {
  padding-left: 66.66667%;
  box-sizing: content-box;
}

.offset-8 {
  margin-left: calc(66.66667% + 10px);
}

.widescreen .offset-8 {
  margin-left: calc(66.66667% + 15px);
}

.span-9 {
  width: calc(75% - 20px);
}

.widescreen .span-9 {
  width: calc(75% - 30px);
}

.pad-9 {
  padding-left: 75%;
  box-sizing: content-box;
}

.offset-9 {
  margin-left: calc(75% + 10px);
}

.widescreen .offset-9 {
  margin-left: calc(75% + 15px);
}

.span-10 {
  width: calc(83.33333% - 20px);
}

.widescreen .span-10 {
  width: calc(83.33333% - 30px);
}

.pad-10 {
  padding-left: 83.33333%;
  box-sizing: content-box;
}

.offset-10 {
  margin-left: calc(83.33333% + 10px);
}

.widescreen .offset-10 {
  margin-left: calc(83.33333% + 15px);
}

.span-11 {
  width: calc(91.66667% - 20px);
}

.widescreen .span-11 {
  width: calc(91.66667% - 30px);
}

.pad-11 {
  padding-left: 91.66667%;
  box-sizing: content-box;
}

.offset-11 {
  margin-left: calc(91.66667% + 10px);
}

.widescreen .offset-11 {
  margin-left: calc(91.66667% + 15px);
}

.span-12 {
  width: calc(100% - 20px);
}

.widescreen .span-12 {
  width: calc(100% - 30px);
}

.pad-0 {
  padding-left: 0;
}

.offset-0 {
  margin-left: 10px;
}

.widescreen .offset-0 {

  margin-left: 15px;
}


.row {
  margin: 0 auto;
  padding: 10px 0;
  float: none;
  clear: both;
  word-spacing: -0.3em;
  max-width: 1172px;
}

.row * {
  word-spacing: normal;
}

.desktop .row {
  width: calc(96% + 20px);
}

.widescreen .row {
  width: calc(90% + 30px);
  max-width: 1470px;
  padding: 15px 0;
}

/* Excellent method for extending coloured background to edge of screen */
/*

.row.fullwidth.green {
  box-shadow: -300px 0px 0px 0px mediumseagreen, 300px 0px 0px 0px mediumseagreen;
}

.widescreen .row.fullwidth.green {
  box-shadow: -1000px 0px 0px 0px mediumseagreen, 1000px 0px 0px 0px mediumseagreen;
}
*/

/* alternative approach to split background colours - this one goes on the first/last columns */
/*.column.extend-to-left.red {
  box-shadow: -300px 0px 0px 0px #ed0300;
}

.mobile .column.extend-to-left.red {
  box-shadow: -100px 0px 0px 0px #ed0300;
}

.widescreen .column.extend-to-left.red {
  box-shadow: -400px 0px 0px 0px #ed0300;
}

.column.extend-to-right.blue {
  box-shadow: 300px 0px 0px 0px #6bcdff;
}

.mobile .column.extend-to-right.blue {
  box-shadow: 100px 0px 0px 0px #6bcdff;
}

.widescreen .column.extend-to-right.blue {
  box-shadow: 400px 0px 0px 0px #6bcdff;
}
*/

/* The most efficient SCSS-friendly way of doing a split background colour. This one goes on the row. */
/*
.bluered {
  background-color: #6bcdff;
  background-repeat: repeat-y;
  background-position: 100% 0;
  background-size: 50% auto;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><rect fill='#ed0300' x='0' y='0' width='100%' height='100%'/></svg>");
}

.row.fullwidth.bluered {
  box-shadow: -300px 0px 0px 0px #6bcdff, 300px 0px 0px 0px #ed0300;
}

.widescreen .row.fullwidth.bluered {
  box-shadow: -1000px 0px 0px 0px #6bcdff, 1000px 0px 0px 0px #ed0300;
}

*/